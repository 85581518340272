import React from "react";
import Header from "../components/layouts/Header"

function Home(){
    return (
        <Header />
    )
}


export default Home;