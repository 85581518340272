import React from "react";

// Pages
import Home from "./pages/Home";


// CSS
import './App.css';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App(){
  return(

       
        <Router>
          <Routes>
            {/* <Route path="/login" caseSensitive={false} element={<Login />} /> */}
            {/* <Route path="/register" caseSensitive={false} element={<Register />} /> */}
            <Route path="/" caseSensitive={false} element={ <Home />} />
          </Routes>
        </Router>
    
  
  );
}


export default  App;