import React from "react"
import { Link } from "react-router-dom";

// CSS
import HeaderCSS from "../../css/header.module.css"

// Logo Image
import logoImg from "../../images/Logo.svg"

function Header() {
    return (
        <>
            <div className={HeaderCSS.main}>
                <div className={HeaderCSS.headerBox}>
                    <Link to="/">
                        <img className={HeaderCSS.logo} src={logoImg} alt="FK Interior Logo" />
                    </Link>

                    <div className={HeaderCSS.menuContainer}>

                        <ul className={HeaderCSS.menuList}>
                            <Link to="/About"><li>About</li></Link>
                            <li title="Coming Soon!">Shop</li>
                            <Link to="/Gallery"><li>Gallery</li></Link>
                            <Link to="/Services"><li>Services</li></Link>
                            <Link to="/ContactUs"><li>Contact</li></Link>
                        </ul>


                    </div>

                    <div className={HeaderCSS.Container}>
                        <nav role="navigation">
                            <div id={HeaderCSS.menuToggle}>
                                <input type="checkbox" />
                                <span />
                                <span />
                                <span />
                                <ul id={HeaderCSS.menu}>
                                    <Link to="/About"><li>About</li></Link>
                                    <Link to="/Gallery"><li>Gallery</li></Link>
                                    <Link to="/Services"><li>Services</li></Link>
                                    <Link to="/ContactUs"><li>Contact</li></Link>
                                    <a href="https://erikterwan.com/" target="_blank" rel="noopener noreferrer">
                                        <li>Show me more</li>
                                    </a>
                                </ul>
                            </div>
                        </nav>
                    </div>

                </div>
            </div>
        </>
    );
}


export default Header;